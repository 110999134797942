import React,{useRef , useState} from 'react'
import emailjs from '@emailjs/browser';
import { Link } from 'react-router-dom'
import Header from './header'
import Footer from './footer'

export default function Feedback() {
  const [mobileNo, setMobileNo] = useState('');
  const [alphaInput, setAlphaInput] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [courseInput, setCourseInput] = useState('');
  const [messageInput, setMessageInput] = useState('');
  const [facultyInput, setFacultyInput] = useState('');
  // const [isDataSaved, setIsDataSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleFaculty =(event)=>{
    let value = event.target.value;
    setFacultyInput(value)

  };
  


  const handleMessage=(event)=>{
    let value = event.target.value;
    setMessageInput(value)
  };

  const handleMobileNoChange = (event) => {
    let value = event.target.value;

    // Ensure the value contains only numeric and specific characters
    value = value.replace(/[^0-9+\s-()]/g, '');

    // Limit the input to a maximum of 10 characters
    if (value.length > 10) {
      value = value.slice(0, 10);
    }

    setMobileNo(value);
  };

  const handleAlphaInput = (event) => {
    let value = event.target.value;

    // Ensure the value contains only alphabetic characters and spaces
    value = value.replace(/[^A-Za-z_\s]/g, '');

    setAlphaInput(value);
  };

  const handleEmail = (event) => {
    let value = event.target.value;
    if(value.length>0)
    {
      setEmailInput(value);
    }

  };

  const handleCourse = (event) => {
    let value = event.target.value;
    if(value.length>0)
    {
      setCourseInput(value);
    }

  };


const form = useRef();

const sendEmail = (e) => {
e.preventDefault();
// Start loading
setIsLoading(true);

emailjs.sendForm('service_dz1ssbn', 'template_y0kfldb', form.current, 'GO2LeZejiuj9Jshpd')
.then((result) => {

  setMobileNo('');
  setAlphaInput('');
  setEmailInput('');
  setCourseInput('');
  setMessageInput('')
  alert("Thank you for your valuable feedback");
  setIsLoading(false);
}, (error) => {
  console.log("result:"+error.text);
});

};
  return (
 <div>
  <Header/>
  <main>
    <div className="breadcrumb__area include-bg pt-200 pb-150 breadcrumb__overlay" data-background="assets/img/breadcrumb/breadcrumb-bg.jpg" style={{backgroundImage: 'url("assets/img/breadcrumb/breadcrumb-bg.jpg")'}}>
      <div className="container">
        <div className="row">
          <div className="col-xxl-12">
            <div className="breadcrumb__content p-relative z-index-1">
              <h3 className="breadcrumb__title">Feedback</h3>
              <div className="breadcrumb__list">
                <span><a style={{color:"white"}} href="/">Home</a></span>
                <span className="dvdr"><i className="fa-regular fa-angle-right" /></span>
                <span>Feedback</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* contact area start */}
    <section className="contact__area pt-115 pb-120">
      <div className="container">
        <div className="row">
        <div className="col-xxl-3 col-xl-3 col-lg-3"></div>

          <div className="col-xxl-6 col-xl-6 col-lg-6">
            <div className="contact__wrapper">
              <div className="section__title-wrapper mb-40">
              <center> <h2 className="section__title">Give Your Valuable Feed Back<span className="yellow-bg yellow-bg-big"></span></h2></center> 
                
              </div>
              <div className="contact__form mb-30">
                <form ref={form} onSubmit={sendEmail}>
                <div className="col-lg-12">
                  <div className="tp-form__main white-bg mb-30 p-relative">
                  <form ref={form} onSubmit={sendEmail} className="pt-md-2">
                      <div className="tp-form__field">
                        <label htmlFor="name">Student Name *</label><br />
                        <input required placeholder="Enter Student Name" name="std_name" autoComplete="off"  type="text"
                        value={alphaInput} onChange={handleAlphaInput}  />
                      </div>

                      <div className="tp-form__field">
                        <label htmlFor="name">Cousre Name *</label><br />
                        <input type="text" required id="coursename" value={courseInput} onChange={handleCourse} autoComplete="off" placeholder="Enter Cousre Name" name="course_name"  />
                      </div>
                      <div className="tp-form__field">
                        <label htmlFor="email">E-mail</label><br />
                        <input type="email" id="email" autoComplete="off" value={emailInput} onChange={handleEmail} placeholder="Enter Your Email Id"  name="EmailId" />
                      </div>

                      <div className="tp-form__field">
                        <label htmlFor="email">Contact Number*</label><br />
                      <input required placeholder="Enter Contact No. *"  autoComplete="off" id="phoneEnquirypop" className="form-control number-only"
        name="mobile_no" type="text" maxLength={10} value={mobileNo} onChange={handleMobileNoChange} onBlur={handleMobileNoChange} />
                      </div>

                      <div className="tp-form__field">
                        <label htmlFor="name">Faculty Name *</label><br />
                        <input type="text" value={facultyInput} onChange={handleFaculty} required id="facultyName" autoComplete="off" placeholder="Enter Faculty Name" name="faculty_name"  />
                      </div>


                      <div className="tp-form__field">
                        <label htmlFor="phone">Today's Date*</label><br />
                        <input autoComplete="off"  id="today" className="form-control" name="to_day" type="date" />
                      </div>
                      <div className="tp-form__field">
                        <label htmlFor="phone">Are you satisfied  with the DITI?*</label><br />
                        <select className="form-control" >
                          <option value="-Select-">-Select-</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div className="tp-form__field">
                        <label htmlFor="phone">How satisfied with your Teacher The way of Teaching?*</label><br />
                        <select className="form-control" >
                          <option value="-Select-">-Select-</option>
                          <option value="Not Satisfied">Not Satisfied</option>
                          <option value="Only 25%">Only 25%</option>
                          <option value="Only 50%">Only 50%</option>
                          <option value="Only 75%">Only 75%</option>
                          <option value="Only 100%">Only 100%</option>
                          {/* <option value="Only 25%">Only 25%</option> */}

                        </select>
                      </div>


                      <div className="tp-form__field">
                       <label htmlFor="phone">Rate your Teacher?*</label><br />
                       <div>
                        <span style={{paddingLeft: "30px"}}>1</span>
                        <span style={{paddingLeft: "20px"}}>2</span>
                        <span style={{paddingLeft: "19px"}}>3</span>
                        <span style={{paddingLeft: "18px"}}>4</span>
                        <span style={{paddingLeft: "18px"}}>5</span>
                        <span style={{paddingLeft: "18px"}}>6</span>
                        <span style={{paddingLeft: "18px"}}>7</span>
                        <span style={{paddingLeft: "18px"}}>8</span>
                        <span style={{paddingLeft: "18px"}}>9</span>
                        <span style={{paddingLeft: "16px"}}>10</span>
                        </div>
                        <div>
                        
                          <span style={{width:"27px", height:"14px"}}>0%</span>
                          <span><input type="radio" class="radio" name="x" value="y" id="y" style={{width:"27px", height:"14px"}} /></span>
                          <span><input type="radio" class="radio" name="x" value="y" id="y" style={{width:"27px", height:"14px"}} /></span>
                          <span><input type="radio" class="radio" name="x" value="y" id="y" style={{width:"27px", height:"14px"}} /></span>
                          <span><input type="radio" class="radio" name="x" value="y" id="y" style={{width:"27px", height:"14px"}} /></span>
                          <span><input type="radio" class="radio" name="x" value="y" id="y" style={{width:"27px", height:"14px"}} /></span>
                          <span><input type="radio" class="radio" name="x" value="y" id="y" style={{width:"27px", height:"14px"}} /></span>
                          <span><input type="radio" class="radio" name="x" value="y" id="y" style={{width:"27px", height:"14px"}} /></span>
                          <span><input type="radio" class="radio" name="x" value="y" id="y" style={{width:"27px", height:"14px"}} /></span>
                          <span><input type="radio" class="radio" name="x" value="y" id="y" style={{width:"27px", height:"14px"}} /></span>
                          <span><input type="radio" class="radio" name="x" value="y" id="y" style={{width:"27px", height:"14px"}} /></span>
                          <span style={{width:"27px", height:"14px"}}>100%</span>

                        </div>
                        
                        
                      </div>

                      <div className="tp-form__field">
                        <label htmlFor="1">Any Suggestions *</label><br />
                      <textarea  placeholder="Enter Message" autoComplete="off" value={messageInput} onChange={handleMessage} className="form-control" name="Any Suggestions" type="text"></textarea>
                      </div>

                      <div className="tp-form__btn">
                        <center><button className="tp-btn"  style={{cursor: 'pointer'}}>
                          <span>Submit <i className="fa-regular fa-arrow-right" /> </span>
                        </button>
                        {/* <button onClick={handleSubmit} disabled={isLoading}>Save</button> */}
                        {isLoading && <p style={{fontSize: "17px"}}>Loading Please Wait...</p>}
                        {/* {isDataSaved && !isLoading && <p>Data has been successfully saved!</p>} */}
                        </center>
                      </div>
                    </form>
                    <div className="tp-form__main-shape">
                      <img src="assets/img/icons/dots-shapes.png" alt />
                    </div>
                  </div>
                </div>
                </form>
              </div>
              <div className="contact-response">
                <p className="ajax-response" />
              </div>
            </div>
          </div>
          <div className="col-xxl-3 col-xl-3 col-lg-3"></div>
          <div className="col-xxl-4 offset-xxl-1 col-xl-4 offset-xl-1 col-lg-5 offset-lg-1">
            <div className="contact__info white-bg p-relative z-index-1">
              <div className="contact__shape">
                
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* contact area end */}
    
  </main>
  <Footer/>
</div>

  )
}
